import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout/Layout'
import BannerImage from '../components/Page/BannerImage'
import FestivalFriend from '../components/Page/FestivalFriend'
import PaypalDonateFormCard from '../components/Form/PaypalDonateFormCard'
import ContactForm from '../components/Form/ContactForm'

const friendsPage = ({data}) => {
	return (
        <Layout>
			<BannerImage image={data.file.childImageSharp.gatsbyImageData} />
			<section className="flex flex-row items-start flex-wrap pt-12 pb-16 bg-boswell-handwriting">
				<div className="md:w-1/2">
					<FestivalFriend />
				</div>
				<div className="md:w-1/2 p-4 md:p-12">
					<PaypalDonateFormCard
						title={`Become a Friend of the Festival`}
						initialValue={25}
						valueText={`£25`}
						minValue={25}
						maxValue={25}
						description={(
							<ul className={`list-disc list-inside`}>
								<li>Priority booking</li>
								<li>Regular newsletters</li>
							</ul>
						)}
						headerClassName={`bg-bbf-yellow`}
					/>

					<div className={`mb-12`}></div>

					<PaypalDonateFormCard
						title={`Become Friends (Joint)`}
						initialValue={35}
						valueText={`£35`}
						minValue={35}
						maxValue={35}
						description={(
							<ul className={`list-disc list-inside`}>
								<li>Priority booking</li>
								<li>Regular newsletters</li>
							</ul>
						)}
						headerClassName={`bg-yellow-500`}
					/>

					<div className={`hidden`}>
						<ContactForm enquiryType={`friend`} />
					</div>
				</div>
			</section>
		</Layout>
    );
}

export const query = graphql`{
  file(relativePath: {eq: "general/Bozzy-16-9-006.jpg"}) {
    childImageSharp {
      gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
    }
  }
}
`

export default friendsPage
